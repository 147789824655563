const state = {
  tab: 0,
  date: {
    startDate: null,
    endDate: null,
  },
  vehicle: [],
  driver: [],
  shipment: null,
  selected: [],
  district: [],
  city: null,
};

const mutations = {
  setTab(state, val) {
    state.tab = val;
  },
  setDate(state, val) {
    state.date = val;
  },
  setVehicle(state, val) {
    state.vehicle = val;
  },
  setDriver(state, val) {
    state.driver = val;
  },
  setDistrict(state, val) {
    state.district = val;
  },
  setCity(state, val) {
    state.city = val;
  },
  setSelected(state, val) {
    state.selected = val;
    // console.log("selected", state.selected);
  },
  removeSelected(state, val) {
    //remove val from selected
    state.selected = state.selected.filter(
      (item) => item.delivery_order_id !== val.delivery_order_id
    );
  },
  addSelected(state, val) {
    //add val to selected
    state.selected.push(val);
  },
  setShipment(state, val) {
    state.shipment = val;
  },
};

const actions = {
  updateTab({ commit }, val) {
    commit("setTab", val);
  },
  updateDate({ commit }, val) {
    commit("setDate", val);
  },
  updateVehicle({ commit }, val) {
    commit("setVehicle", val);
  },
  updateDriver({ commit }, val) {
    commit("setDriver", val);
  },
  updateSelected({ commit }, val) {
    commit("setSelected", val);
  },
  updateDistrict({ commit }, val) {
    commit("setDistrict", val);
  },
  updateCity({ commit }, val) {
    commit("setCity", val);
  },
  updateShipment({ commit }, val) {
    commit("setShipment", val);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
